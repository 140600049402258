import { StreamfieldBlock } from "interfaces";
import BackgroundOne from "public/svg/spacer-background-1.svg";
import BackgroundTwo from "public/svg/spacer-background-2-1.svg";

import styles from "./spacer.module.scss";

export interface Spacer {
    anchor?: string;
    height:
        | "no-height"
        | "small"
        | "medium"
        | "large"
        | "xlarge"
        | "xlarge-b1"
        | "xlarge-b2"
        | "xxlarge";
}

const Spacer = (spacer: Spacer) => {
    let child: any;
    switch (spacer.height) {
        case "xlarge-b1":
            child = <BackgroundOne preserveAspectRatio="none" />;
            break;
        case "xlarge-b2":
            child = <BackgroundTwo preserveAspectRatio="none" />;
            break;
        default:
            child = null;
    }

    const height = spacer.height.startsWith("xlarge")
        ? "xlarge"
        : spacer.height;

    if (spacer.anchor) {
        return (
            <a
                id={spacer.anchor}
                className={`${styles.spacer} ${styles[height]}`}
            >
                {child}
            </a>
        );
    } else {
        return (
            <div
                aria-hidden="true"
                className={`${styles.spacer} ${styles[height]}`}
            >
                {child}
            </div>
        );
    }
};

export default Spacer;

export type SpacerBlock = StreamfieldBlock<"spacer", Spacer>;
