import { Tooltip as ReachTooltip } from "@reach/tooltip";
import "@reach/tooltip/styles.css";
import React from "react";

import styles from "./tooltip.module.scss";

interface TooltipProps {
    label: React.ReactNode;
    children: React.ReactNode;
}

export const Tooltip = ({ children, label }: TooltipProps) => (
    <ReachTooltip label={label} className={styles.tooltip}>
        {children}
    </ReachTooltip>
);
