import { VisuallyHidden } from "@reach/visually-hidden";
import classNames from "classnames";
import Clickable from "components/Clickable";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import { Tooltip } from "components/Tooltip/Tooltip";
import {
    getProductImageTransforms,
    imagePlaceholder,
    slugify,
} from "helpers/utils";
import { ProductGridTile as ProductGridTileCms } from "interfaces/products";
import { forwardRef } from "react";

import styles from "./product-grid-tile.module.scss";
import productGridStyles from "./product-grid.module.scss";

interface ProductGridTileProps extends ProductGridTileCms {
    /**
     * There are some situations where we want to eagerly load images for page
     * performance purposes (Largest contentful paint above the fold)
     */
    eagerLoadImage: boolean;
}

export const ProductGridTile = (props: ProductGridTileProps) => (
    <article className={styles.tile}>
        {props.merchandising_tag && (
            <aside className={styles.tag}>{props.merchandising_tag}</aside>
        )}
        <Clickable
            href={props.url}
            className={`${
                styles.clickable
            } al-grid__tile al-grid__tile--${slugify(props.title)}`}
        >
            <div className={styles.imageContainer}>
                <CmsResponsiveImage
                    cmsImage={{
                        url: props.image?.image ?? imagePlaceholder,
                    }}
                    sizes="(min-width: 0px) 50vw, (min-width: 1024px) 21vw, (min-width: 1480px) 275px"
                    cldSrcSet={[275, 550, 825, 1100]}
                    cloudinaryProps={{
                        ...getProductImageTransforms(props.image_padding),
                        type: props.image?.image ? "fetch" : "upload",
                    }}
                    loading={props.eagerLoadImage ? "eager" : "lazy"}
                />
            </div>
            <div className={styles.tileTextContainer}>
                <h2 className="h6">{props.title}</h2>
                {!!props.finishes && props.finishes.length > 0 && (
                    <p>
                        {props.finishes.length} finish
                        {props.finishes.length !== 1 ? "es" : ""}
                    </p>
                )}
                {!!props.sizes && props.sizes.length > 0 && (
                    <p>
                        Available for {props.sizes.length} size
                        {props.sizes.length !== 1 ? "s" : ""}
                    </p>
                )}
                {!!props.icons && props.icons.length > 0 && (
                    <div className={styles.iconGroup}>
                        {props.icons.map((icon, index) => (
                            <Tooltip
                                key={`${props.id}-icon-${index}`}
                                label={icon.title}
                            >
                                <Clickable unstyled={true}>
                                    <CmsResponsiveImage
                                        className={styles.icon}
                                        cmsImage={{ url: icon.image }}
                                        decorative={true}
                                        sizes="42px"
                                        cldSrcSet={[42, 84, 126, 168]}
                                        cloudinaryProps={{ effect: "trim" }}
                                    />
                                </Clickable>
                            </Tooltip>
                        ))}
                    </div>
                )}
            </div>
        </Clickable>
    </article>
);

interface Props {
    tiles: ProductGridTileCms[];
    loading: boolean;
    tilesPerRow?: 3 | 4;
}

export const ProductGrid = forwardRef<HTMLUListElement, Props>(
    function ProductGrid({ tiles, loading, tilesPerRow = 3 }, ref) {
        if (!loading)
            return (
                <ul
                    className={classNames([
                        productGridStyles.gridList,
                        productGridStyles[`perRow${tilesPerRow}`],
                    ])}
                    ref={ref}
                >
                    {tiles.map((tile, index) => (
                        <li key={tile.id} className={styles.tileListItem}>
                            <ProductGridTile
                                {...tile}
                                eagerLoadImage={index < tilesPerRow}
                            />
                        </li>
                    ))}
                    {/* TODO: configure when to display marketing tiles based on CMS data */}
                    {/* TODO: make sure this isn't showing in search results */}
                    {/* <li>
          <GridMarketingTile />
        </li> */}
                </ul>
            );

        const fakeListItems = [];

        for (let i = 0; i < 12; i++) {
            fakeListItems.push(
                <li
                    key={i}
                    className={classNames([
                        styles.clickable,
                        styles.fakeGridTile,
                    ])}
                >
                    <div className={styles.imageContainer}></div>
                    <h1 className="h6">Fake Title, Nice Length</h1>
                    <p>0 Finishes</p>
                    <p>Available in 0 sizes</p>
                </li>,
            );
        }

        return (
            <div>
                <VisuallyHidden>Products are loading</VisuallyHidden>
                <ul
                    aria-hidden="true"
                    className={classNames([
                        productGridStyles.gridList,
                        productGridStyles[`perRow${tilesPerRow}`],
                    ])}
                    ref={ref}
                >
                    {fakeListItems}
                </ul>
            </div>
        );
    },
);
