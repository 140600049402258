import { useMixedCheckbox } from "@reach/checkbox";
import React from "react";

import Checkmark from "../../public/svg/checkmark.svg";

import styles from "./checkbox.module.scss";

export const Checkbox = ({
    children,
    value,
    name,
    ...props
}: Partial<Parameters<typeof useMixedCheckbox>[1]> & {
    children: any;
    value: string;
    name?: string;
}) => {
    let inputRef = React.useRef<HTMLInputElement>(null);
    let [inputProps] = useMixedCheckbox(inputRef, {
        ...props,
    });
    return (
        <label className={styles.root}>
            <input {...inputProps} ref={inputRef} value={value} name={name} />
            <span aria-hidden className={styles.checkbox}>
                <Checkmark className={styles.checkmark} />
            </span>
            {children}
        </label>
    );
};
